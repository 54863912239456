<template>
  <div class="pensionables">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Hej {{ givenName }}
          </h1>
          <h2 class="subtitle" v-if="currentData && currentData.delegate">
            Här är alla pensionsberättigade som du är rådgivare för
          </h2>
          <h2 class="subtitle" v-else-if="currentData && currentData.employerContactPerson">
            Här är alla pensionsberättigade för ert företag
          </h2>
          <h2 class="subtitle is-size-6">Visa för månad/år:
            <select v-model="pickedLedgerMonth">
              <option v-for="ledger in ledgerMonths"
                :value="ledger"
                :key="ledger">
                {{ ledger }}
              </option>
            </select>
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <!--
        <table class="table">
          <thead>
            <tr>
              <th>Namn</th>
              <th>Personnummer</th>
              <th>Företag</th>
              <th>Organisationsnummer</th>
              <th>Totalt kapitalvärde</th>
              <th>Tillgängligt kapital</th>
              <th>Utbetalning</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="beneficiary in beneficiaries" :key="beneficiary.personalNumber">
              <td>{{ beneficiary.name }}</td>
              <td>{{ beneficiary.personalNumber }}</td>
              <td>{{ beneficiary.companyName }}</td>
              <td>{{ beneficiary.companyOrgno }}</td>
              <td>0 kr</td>
              <td>0 kr</td>
              <td>0 kr</td>
            </tr>
          </tbody>
        </table>
        -->
          <TableRender :headers="[
            { key: 'name', title: 'Namn', is_link: true},
            { key: 'personalNumber', title: 'Personnummer' },
            { key: 'companyName', title: 'Företag' },
            { key: 'companyOrgno', title: 'Org.nummer' },
            { key: 'lastUpdate', title: 'Senast uppdaterad' },
            { key: 'capitalValue', title: 'Totalt kapitalvärde', type: 'money' },
         /* { key: 'availibleExcess', title: 'Tillgängligt kapital', type: 'money' }, */
            { key: 'payout', title: 'Utbetalning Lön', type: 'money' },
            { key: 'payoutTax', title: 'Utbetalning Skatt' },
            { key: 'payoutPeriod', title: 'Utbetalning Period' },
          ]"
          :content="beneficiaries"/>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import leftPad from 'left-pad'
import TableRender from '@/components/TableRender'

export default Vue.extend({
  name: 'pensionables',
  components: {
    TableRender
  },
  data () {
    return {
      pickedLedgerMonth: null
    }
  },
  methods: {
    monthToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0')
    },
    dateToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0') + '-' +
        leftPad(date.day, 2, '0')
    },
    pensionableUrl (penId) {
      let q = '?b=' + penId
      if (this.$route.query.p) {
        q += '&p=' + this.$route.query.p
      }
      return '/t/' + this.$route.params.trust + q
    }
  },
  asyncComputed: {
    currentDataBeneficiaries: {
      async get () {
        if (!this.currentData || (!this.currentData.delegate && !this.currentData.employerContactPerson)) {
          return []
        }
        const result = await Promise.all((this.currentData.delegate?.delegateBeneficiaries || []).map(ben => {
          return this.$store.state.profile.apiClient.getFor(this.$route.params.trust, ben)
            .catch(() => {
              return null
            })
        }))

        const ecpBeneficiaries = []
        ;(this.currentData.employerContactPerson || []).forEach(ecp => {
          ecp.contactEmployers.forEach(ce => {
            ce.employerBeneficiaries.forEach(ben => {
              if (ecpBeneficiaries.includes(ben)) {
                return
              }
              ecpBeneficiaries.push(ben)
            })
          })
        })

        const resultECP = await Promise.all(ecpBeneficiaries.map(ben => {
          return this.$store.state.profile.apiClient.getFor(this.$route.params.trust, ben).catch(() => {
            return null
          })
        }))

        return [...result, ...resultECP].filter(Boolean).map(result => result.data.beneficiary).filter(Boolean)
      },
      default () {
        return []
      }
    },
    currentData: {
      async get () {
        if (!this.$store.state.profile.raw) {
          return null
        }
        const selectedTrust = this.$store.state.profile.raw.find(r => {
          return r.TrustSlug === this.$route.params.trust
        })

        if (this.$route.query.p) {
          const specTrust = await this.$store.state.profile.apiClient.getFor(this.$route.params.trust, this.$route.query.p)
          return specTrust.data
        }

        return selectedTrust
      },
      default () {
        return null
      }
    }
  },
  computed: {
    givenName () {
      if (this.currentData && this.currentData.delegate) {
        return this.currentData.delegate.delegateName
      }
      if (this.currentData && this.currentData.employerContactPerson) {
        return this.currentData.employerContactPerson[0].contactEmployers[0].contactName
      }
      return null
    },
    ledgerMonths () {
      return this.beneficiaries
        .map(ben => ben._forMonths.map(v => this.monthToHuman(v)))
        .reduce((cur, ben) => cur.concat(ben), [])
        .filter((v, i, s) => s.indexOf(v) === i)
    },
    beneficiaries () {
      if (!this.currentData || (!this.currentData.delegate && !this.currentData.employerContactPerson)) {
        return []
      }
      return this.currentDataBeneficiaries.map(ben => {
        const sortedLedgers = (ben.ledger ? [...ben.ledger] : []).sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year
          }
          if (a.month !== b.month) {
            return a.month - b.month
          }
          return a.day - b.day
        }).reverse()
        const latestLedger = sortedLedgers.length > 0 ? sortedLedgers[sortedLedgers.length - 1] : null

        if (latestLedger && !this.pickedLedgerMonth) {
          this.pickedLedgerMonth = this.monthToHuman(latestLedger.forMonth)
        }

        const pickedLedger = sortedLedgers.reduce((cur, led) => {
          if (this.pickedLedgerMonth === this.monthToHuman(led.forMonth)) {
            cur = led
          }
          return cur
        }, latestLedger)

        let total = 0
        if (pickedLedger && pickedLedger.rows) {
          pickedLedger.rows.forEach(row => {
            total += Math.floor(row.amountInCent / 100)
          })
        }
        let payoutPeriod = '-'
        if (pickedLedger && pickedLedger.payFirstday && pickedLedger.payLastday) {
          payoutPeriod = `${this.dateToHuman(pickedLedger.payFirstday)} till ${this.dateToHuman(pickedLedger.payLastday)}`
        } else if (pickedLedger && pickedLedger.payOneoff) {
          payoutPeriod = `${this.dateToHuman(pickedLedger.payOneoff)}`
        }
        return {
          _forMonths: sortedLedgers.map(l => ({ month: l.forMonth.month, year: l.forMonth.year })),
          name: {
            href: this.pensionableUrl(ben.personalNumber),
            user: ben,
            value: ben.name
          },
          personalNumber: {
            value: ben.personalNumber
          },
          companyName: {
            value: ben.companies.map(c => c.companyName)
          },
          companyOrgno: {
            value: ben.companies.map(c => c.companyOrgno)
          },
          lastUpdate: {
            value: pickedLedger ? this.dateToHuman(pickedLedger.lastUpdate) : '-'
          },
          capitalValue: {
            value: total
          },
          /*
          availibleExcess: {
            value: pickedLedger && pickedLedger.totalExcessInCent > 0 ? Math.floor(pickedLedger.totalExcessInCent / 100) : 0
          },
          */
          payout: {
            value: pickedLedger && pickedLedger.payInCent > 0 ? Math.floor(pickedLedger.payInCent / 100) : null
          },
          payoutTax: {
            value: pickedLedger && pickedLedger.payTaxInPercent ? `${pickedLedger.payTaxInPercent} %` : ''
          },
          payoutPeriod: {
            value: payoutPeriod
          }
        }
      })
    }
  },
  watch: {
    currentData () {
      this.pickedLedgerMonth = null
      if (this.currentData && (!this.currentData.delegate || !this.currentData.delegate.delegateId) && !this.currentData.employerContactPerson) {
        this.$router.push('/')
      }
    }
  },
  beforeCreate: function () {
    // if (!this.$store.state.profile.delegate || !this.$store.state.profile.delegate.delegateId) {
    //  this.$router.push('/')
    // }
  }
})
</script>

<style lang="scss">
  .pensionables .table-container {
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 400px;
    max-height: calc(100vh - 330px);
    border: 2px solid #ececec;
    table td span {
      white-space: nowrap;
    }
    thead th {
      position: sticky;
      top: 0;
      background-color: white;
      border-bottom: 1px solid #f1f1f1;
    }
  }
</style>
