var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pensionables"},[_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(" Hej "+_vm._s(_vm.givenName)+" ")]),(_vm.currentData && _vm.currentData.delegate)?_c('h2',{staticClass:"subtitle"},[_vm._v(" Här är alla pensionsberättigade som du är rådgivare för ")]):(_vm.currentData && _vm.currentData.employerContactPerson)?_c('h2',{staticClass:"subtitle"},[_vm._v(" Här är alla pensionsberättigade för ert företag ")]):_vm._e(),_c('h2',{staticClass:"subtitle is-size-6"},[_vm._v("Visa för månad/år: "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pickedLedgerMonth),expression:"pickedLedgerMonth"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.pickedLedgerMonth=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.ledgerMonths),function(ledger){return _c('option',{key:ledger,domProps:{"value":ledger}},[_vm._v(" "+_vm._s(ledger)+" ")])}),0)])])])]),_c('section',[_c('div',{staticClass:"container"},[_c('TableRender',{attrs:{"headers":[
          { key: 'name', title: 'Namn', is_link: true},
          { key: 'personalNumber', title: 'Personnummer' },
          { key: 'companyName', title: 'Företag' },
          { key: 'companyOrgno', title: 'Org.nummer' },
          { key: 'lastUpdate', title: 'Senast uppdaterad' },
          { key: 'capitalValue', title: 'Totalt kapitalvärde', type: 'money' },
       /* { key: 'availibleExcess', title: 'Tillgängligt kapital', type: 'money' }, */
          { key: 'payout', title: 'Utbetalning Lön', type: 'money' },
          { key: 'payoutTax', title: 'Utbetalning Skatt' },
          { key: 'payoutPeriod', title: 'Utbetalning Period' } ],"content":_vm.beneficiaries}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }