<template>
  <div class="sparande">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Hej {{ givenName }}, så här ser ditt sparande ut
          </h1>
          <h2 class="subtitle">
            Fonder, aktier och portföljer
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'sparande',
  data () {
    return {
      givenName: (this.$store.getters.isLoggedIn) ? this.$store.getters.session.account.givenName : null
    }
  },
  beforeCreate: function () {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/')
    }
  }
}
</script>
